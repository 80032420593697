import { useState } from "react";
import { toast } from "react-toastify";
import { setNewPassword } from "../../api/userApi";
import { useAuth } from "../../hooks/useAuth";
import { UserData } from "../../types/auth-data";
import { PasswordModalProps } from "../../types/modal";
import { validatePassword } from "../../utils/validator-utils";

/**
 * Display a modal to set new password when logging in for the first time.
 * @returns
 */
function PasswordModal(props: Readonly<PasswordModalProps>) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setAuth } = useAuth();

  const handleSubmit = async () => {
    try {
      // validate the inputs
      let isValid = true;
      const passwordValidation = validatePassword(password);
      if (!passwordValidation.isValid) {
        setPasswordError(passwordValidation.error);
        isValid = false;
      } else {
        setPasswordError("");
      }

      if (isValid) {
        setIsLoading(true);
        const response = await setNewPassword({ new_password: password });

        if (response.data.statusCode === 200) {
          const status = response.data.data.status;
          // set new user status
          setAuth((prev) => ({ ...prev, status }) as UserData);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while changing password.");
    } finally {
      setIsLoading(false);
      props.onCancel();
    }
  };

  return (
    <div className="modal display-block">
      <div className="modal-main">
        <div className="modal-head">
          <h2>Update your password</h2>
        </div>
        <div className="modal-body d-block position-relative">
          {isLoading && (
            <div className="loader-overlay position-absolute">
              <div className="spinner-border text-primary" role="status" />
            </div>
          )}
          <div className="form-wrapper">
            <div className="input-wrapper">
              <label htmlFor="frameName" className="form-label text-start">
                New password
              </label>
              <input
                autoFocus
                type="password"
                name="frame-name"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Type in a new password"
              />
            </div>
            <div className="input-wrapper mt-3">
              <label htmlFor="frameName" className="form-label text-start">
                Confirm new password
              </label>
              <input
                autoFocus
                type="password"
                name="frame-name"
                className="form-control"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Type the same password again"
              />
              {passwordError && <p className="error">{passwordError}</p>}
            </div>
          </div>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-danger"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default PasswordModal;
