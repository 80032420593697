import React from "react";

export default function NotFound() {
  return (
    <div className="centered-container">
      <div className="text-center">
        <h1>Nothing here..</h1>
        <h2 className="text-light">Please get the correct URL to view pages.</h2>
      </div>
    </div>
  );
}
