import React from "react";
import { PageDeleteConfirmationModalProps } from "../../types/modal";

export default function PageDeleteConfirmationModal(
  props: Readonly<PageDeleteConfirmationModalProps>
) {
  return (
    <div className="modal display-block">
      <div className="modal-main">
        <div className="modal-head">
          <h2>Delete page {props.frameName}?</h2>
        </div>
        <div className="modal-body d-block">
          <div>
            <p>
              Are you sure to delete page <b>{props.frameName}</b> from Figma
              Press?
              {props.isUpdatePending && <em>
                <br />
                You will lose all your content updates of this page. This action
                can't be undone!
              </em>}
            </p>
          </div>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={props.onDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
