import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH_LOGIN, USER_STATUS } from "../constants";
import { useAuth } from "../hooks/useAuth";
import Navbar from "./NavBar";

/**
 * Provide access to the child components only for authenticated users.
 * @param param0
 * @returns
 */
export function AuthenticatedRoute({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const { userData, isPending } = useAuth();
  const location = useLocation();

  // loading spinner if auth state is yet to be hydarated
  if (isPending) {
    return (
      <div className="loader-overlay">
        <div className="spinner-border text-primary" role="status" />
      </div>
    );
  }

  // if user is not logged in return to login page
  if (!isPending && !userData) {
    toast("You are not logged in.");
    // Redirect to login page, saving the attempted URL
    return (
      <Navigate
        to={PATH_LOGIN}
        state={{ from: location, requirePassword: false }}
        replace
      />
    );
  }

  // if user is not active - password has not been changed for the first login
  if (!isPending && userData && userData.status === USER_STATUS.PENDING) {
    toast("Please update your default password to login.");
    // Redirect to login page, saving the attempted URL
    return (
      <Navigate
        to={PATH_LOGIN}
        state={{ from: location, requirePassword: true }}
        replace
      />
    );
  }

  return (
    <>
      <Navbar />
      <div className="page-content">{children}</div>
    </>
  );
}
