import { MouseEvent, useState } from "react";
import { toast } from "react-toastify";
import { updateDesignAccessForUser } from "../../api/userApi";
import { USER_ROLE } from "../../constants";
import { DesignInvitationModalProps } from "../../types/modal";
import { formatRoleName } from "../../utils/auth-utils";
import { copyToClipboard } from "../../utils/common-utils";
import { validateEmail } from "../../utils/validator-utils";
import { useAuth } from "../../hooks/useAuth";

/**
 * Renders a modal for iniviting users by their email to a design.
 *
 * @param props
 * @returns
 */
export default function InvitationModal(
  props: Readonly<DesignInvitationModalProps>
) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedRole, setSelectedRole] = useState<USER_ROLE>(USER_ROLE.USER);
  const [isLoading, setIsLoading] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState("Copy credentials");

  const { userData } = useAuth();

  // capture data of new user - if invited for the first time
  const [newUserData, setNewUserData] = useState<{
    email: string;
    role: USER_ROLE;
    password?: string;
  } | null>(null);

  // reset the form back to initial for adding another user
  const resetForm = () => {
    setEmail("");
    setSelectedRole(USER_ROLE.USER);
    setNewUserData(null);
  };

  const handleInvite = async (e: MouseEvent) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      let isValid = true;

      // Validate email
      if (!validateEmail(email)) {
        setEmailError("Please enter a valid email address.");
        isValid = false;
      } else {
        setEmailError("");
      }

      // validate role - set default USER role
      if (!selectedRole) {
        setSelectedRole(USER_ROLE.USER);
      }

      if (isValid) {
        const response = await updateDesignAccessForUser({
          email,
          design_id: props.design_id,
          role: selectedRole,
        });

        if (response.data.statusCode === 200) {
          // TODO: this should be removed when the email sending is impl in BE
          // show the password only for new users
          if (response.data.data.password) {
            setNewUserData({
              email,
              role: response.data.data.role,
              password: response.data.data.password,
            });
          } else {
            // reset the form for existing users
            resetForm();
          }
          toast.success("User access granted.");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error while inviting the user");
    } finally {
      setIsLoading(false);
    }
  };

  //   copy the password to clipboard
  //   temporarily change the copy button text
  const copyCredentials = () => {
    if (!newUserData?.password) return;
    setCopyButtonText("Copied to clipboard");
    copyToClipboard(newUserData?.password);

    setTimeout(() => setCopyButtonText("Copy credentials"), 1500);
  };

  return (
    <div className="modal display-block">
      <div className="modal-main">
        <div className="modal-head">
          <h2>
            Invite users to colloborate in <em>{props.design_name}</em>
          </h2>
        </div>
        <div className="modal-body d-block position-relative">
          {isLoading && (
            <div className="loader-overlay position-absolute">
              <div className="spinner-border text-primary" role="status" />
            </div>
          )}
          {newUserData?.password ? (
            <div className="form-wrapper d-grid credentials-view">
              <p>Email:</p> <p>{email}</p>
              <p>Password:</p> <p>{newUserData.password}</p>
              <em className="credentials-view-full">
                Copy above before proceeding.
              </em>
            </div>
          ) : (
            <div className="form-wrapper">
              <div className="input-wrapper">
                <label htmlFor="frameName" className="form-label text-start">
                  Email
                </label>
                <input
                  autoFocus
                  type="email"
                  name="frame-name"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Type in an email to invite"
                />
                {emailError && <p className="error">{emailError}</p>}
              </div>
              <div className="input-wrapper mt-3">
                <label htmlFor="frameName" className="form-label text-start">
                  Select role
                </label>
                <select
                  name="frame-name"
                  className="form-control"
                  id="role"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value as USER_ROLE)}
                >
                  {userData?.can_assign_roles.map((role) => (
                    <option key={role} value={role}>
                      {formatRoleName(role)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        {newUserData?.password ? (
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-danger"
              onClick={resetForm}
              disabled={isLoading}
            >
              Invite another
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={copyCredentials}
              disabled={isLoading}
            >
              {copyButtonText}
            </button>
          </div>
        ) : (
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={props.onCancel}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleInvite}
              disabled={!email || !!emailError || isLoading}
            >
              Invite
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
