import React from "react";
import { FrameMetadataEditorModalProps } from "../../types/modal";
import { MdOutlineKeyboardCapslock } from "react-icons/md";
import { PiKeyReturnLight } from "react-icons/pi";

export default function FrameMetadataEditorModal(
  props: Readonly<FrameMetadataEditorModalProps>
) {
  /**
   * Handle Enter key press & Ctrl + Enter key press
   * Ctrl + Enter - Save the text & add a new line
   * Enter - Save the text
   * @param e - Keyboard event
   * @returns void
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (e.ctrlKey) {
        // Allow new line when Ctrl + Enter is pressed
        e.preventDefault(); // Prevent default behavior of Enter key
        props.onSave(); // Call the save function
      } else {
        return; // Do nothing, let the default behavior occur
      }
    } else if (e.key === "Escape") {
      props.onCancel();
    }
  };

  return (
    <div className="modal display-block">
      <div className="modal-main">
        <div className="modal-head">
          {props.isForDuplicate ? (
            <h2>Set name for new page</h2>
          ) : (
            <h2>Update name of '{props.oldName}'</h2>
          )}
        </div>
        <div className="modal-body d-block">
          <div className="input-wrapper">
            <label htmlFor="frameName" className="form-label text-start">
              Name
            </label>
            <input
              autoFocus
              type="text"
              name="frame-name"
              className="form-control"
              id="frameName"
              value={props.frameUpdate?.newName || ""}
              onKeyDown={handleKeyDown}
              onChange={(e) => props.onStringChange(e.target.value)}
              placeholder="Type a name for new page"
            />
          </div>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={props.onSave}
            title="Ctrl + Enter to save"
          >
            Save
            <MdOutlineKeyboardCapslock />
            <PiKeyReturnLight />
          </button>
        </div>
      </div>
    </div>
  );
}
