import { createContext } from "react";
import { UserData } from "../types/auth-data";

/**
 * Holds the current user authentication status.
 */
export const AuthContext = createContext<{
  userData: UserData | null;
  setAuth: React.Dispatch<React.SetStateAction<UserData | null>>;
  isPending: boolean;
  setIsPending: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  userData: null,
  setAuth: () => {},
  isPending: false,
  setIsPending: () => {},
});
