import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { AuthProvider } from "./components/AuthProvider";
import {
  PATH_LOGIN,
  PATH_PARAM_DESIGN_ID,
  PATH_PARAM_FRAME_ID,
} from "./constants";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import SingleDesignView from "./pages/SingleDesignView";
import SingleFrameView from "./pages/SingleFrameView";

function App() {
  return (
    <div className="App">
      <ToastContainer position="top-right" theme="colored" limit={1} />
      <Router>
        <AuthProvider>
          <Routes>
            <Route path={PATH_LOGIN} element={<Login />} />
            <Route
              path={`/:${PATH_PARAM_DESIGN_ID}`}
              element={
                <AuthenticatedRoute>
                  <SingleDesignView />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`/:${PATH_PARAM_DESIGN_ID}/:${PATH_PARAM_FRAME_ID}`}
              element={
                <AuthenticatedRoute>
                  <SingleFrameView />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/"
              element={
                <AuthenticatedRoute>
                  <Dashboard />
                </AuthenticatedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
