import React, { useEffect, useRef, useState } from "react";
import { BsCopy, BsFillXCircleFill } from "react-icons/bs";
import { CiTimer } from "react-icons/ci";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import figmaApi from "../api/figmaApi";
import FrameMetadataEditorModal from "../components/modals/FrameMetadataEditorModal";
import PageDeleteConfirmationModal from "../components/modals/PageDeleteConfirmationModal";
import {
  DeleteFrameData,
  FigmaData,
  FigmaDesignIdentifier,
  FrameMetadataUpdate,
} from "../types";
import { DashboardPathParameters } from "../types/path-parameters";
import { uppercaseWords } from "../utils/common-utils";
import { useAuth } from "../hooks/useAuth";
import { checkIsSuperAdmin } from "../utils/auth-utils";

/**
 * Displays the list of frames in a single design file.
 * @returns
 */
function SingleDesignView() {
  const { userData } = useAuth();
  const { design_id } = useParams<DashboardPathParameters>();
  const hasFetchedData = useRef(false);

  const [data, setData] = useState<Array<FigmaData>>([]);
  const [frameToDelete, setFrameToDelete] = useState<DeleteFrameData | null>(
    null
  );
  const [frameToUpdateMetadata, setFrameToUpdateMetadata] =
    useState<FrameMetadataUpdate | null>(null);
  const [frameToDuplicate, setFrameToDuplicate] = useState<FigmaData | null>(
    null
  );
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const [isFrameMetadataUpdateLoading, setIsFrameMetadataUpdateLoading] =
    useState(false);

  /**
   * Fetch the frames for this design from API & render the list
   * @param data
   */
  const getFramesUnderDesign = async (data: FigmaDesignIdentifier) => {
    try {
      const response = await figmaApi.getFigmaFramesUnderDesign(data);

      if (response.data.statusCode !== 200) {
        throw response.data.message;
      }

      setData(response.data.data);
    } catch (error) {
      console.error(error);
      toast("Did not get any data to display.", { type: "error" });
    }
  };

  // fetch frames under design
  useEffect(() => {
    if (!design_id || hasFetchedData.current) return;
    hasFetchedData.current = true;
    getFramesUnderDesign({ design_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [design_id]);

  // functions for CTA buttons on each line item --
  /**
   * State handler to open metadata editor modal for a frame.
   * @param frame
   */
  const setDataToUpdateFrameMetadata = (
    frame: FigmaData,
    isForDuplicate = false
  ) => {
    // set context for duplicate
    if (isForDuplicate) {
      setFrameToDuplicate(frame);
    } else {
      setFrameToDuplicate(null);
    }

    setFrameToUpdateMetadata({
      design_id: frame.design_id,
      frame_id: frame.frame_id,
      newName: isForDuplicate
        ? `Duplicate of ${frame.frame_name}`
        : frame.frame_name,
      oldName: frame.frame_name,
    });
  };

  const handleDuplicateClick = (frame: FigmaData) => {
    // open metadata editing modal
    setDataToUpdateFrameMetadata(frame, true);
  };

  /**
   * Execute duplicate frame API call with state data.
   * @param duplicatingFrame
   */
  const duplicateFrame = async (duplicatingFrame: FigmaData) => {
    try {
      setIsDuplicateLoading(true);
      const response = await figmaApi.duplicateFrame({
        design_id: duplicatingFrame.design_id,
        frame_id: duplicatingFrame.frame_id,
        frame_name: `${frameToUpdateMetadata?.newName}`,
      });

      if (response.data.statusCode === 400) {
        throw response.data.message;
      }

      // push the newly created frame into data array
      const duplicateFrameData = response.data.data;
      setData((prev) => [...prev, duplicateFrameData]);
      toast("Page duplicated.", { type: "info" });
    } catch (error) {
      console.error(error);
      toast(
        `Error occurred while making duplicate of ${frameToUpdateMetadata?.newName} `,
        { type: "error" }
      );
    } finally {
      setIsDuplicateLoading(false);
      setFrameToDuplicate(null);
    }
  };

  /**
   * Execute delete frame API call with state data.
   * @param deletePageData
   * @returns
   */
  const deleteFrame = async (deletePageData: DeleteFrameData | null) => {
    if (!deletePageData) return;
    try {
      setIsDeleteLoading(true);
      await figmaApi.deleteFrame(deletePageData);

      setData((prev) => [
        ...prev.filter((item) => item.frame_id !== deletePageData.frame_id),
      ]);

      toast("Page deleted.", { type: "info" });
    } catch (error) {
      toast("Error while deleting page.", { type: "error" });
      console.error(error);
    } finally {
      setIsDeleteLoading(false);
      setFrameToDelete(null);
    }
  };

  /**
   * Execute update frame API call with state data.
   * @param updateData
   * @returns
   */
  const updateFrameMetadata = async (
    updateData: FrameMetadataUpdate | null
  ) => {
    if (!updateData) return;

    try {
      setIsFrameMetadataUpdateLoading(true);
      await figmaApi.updateFigmaFrameMetadata(updateData);
      setData((prev) => [
        ...prev.map((frameData) => {
          // only update the name of the selected item in the datalist
          if (frameData.frame_id === updateData.frame_id) {
            return {
              ...frameData,
              frame_name: updateData.newName,
            };
          } else {
            return frameData;
          }
        }),
      ]);
      toast("Page name updated.", { type: "info" });
    } catch (error) {
      toast("Error while updating page name.", { type: "error" });
      console.error(error);
    } finally {
      setIsFrameMetadataUpdateLoading(false);
    }
  };

  /**
   * Handler for submission of frame name editor modal.
   * Choose the succession call based on duplicate frame or existing frame name update.
   */
  const handleFrameMetadataUpdate = () => {
    if (frameToDuplicate) {
      duplicateFrame(frameToDuplicate);
    } else {
      updateFrameMetadata(frameToUpdateMetadata);
    }
    setFrameToUpdateMetadata(null);
  };

  /**
   * Shows a global page loader upon async tasks.
   */
  const isLoading =
    isDeleteLoading || isDuplicateLoading || isFrameMetadataUpdateLoading;

  return (
    <div className="page-content-wrapper">
      {isLoading && (
        <div className="loader-overlay">
          <div className="spinner-border text-primary" role="status" />
        </div>
      )}
      <div className="centered-container">
        <div className="text-center">
          <h1>Figma Press | Eight25</h1>
          {data.length > 0 && (
            <div className="card" style={{ width: "45rem" }}>
              <div className="card-body">
                <h2 className="card-title">
                  {uppercaseWords(data[0].design_name)}
                </h2>
                <ul className="list-group list-group-flush">
                  {data.map((item) => (
                    <li
                      key={item.frame_id}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <span className="frame-name-wrapper">
                        {item.is_updated && (
                          <CiTimer
                            className="pending-update-icon"
                            data-tooltip-id={`pending_update_${item._id}`}
                            data-tooltip-content="Needs syncing.."
                          />
                        )}
                        <Tooltip id={`pending_update_${item._id}`} />
                        <span
                          className="frame-name"
                          onClick={() => setDataToUpdateFrameMetadata(item)}
                          data-tooltip-id={`name_update_${item._id}`}
                          data-tooltip-content="Update name"
                        >
                          {item.frame_name}
                          <Tooltip id={`name_update_${item._id}`} />
                        </span>
                      </span>
                      <div className="action-wrapper d-flex aligen-items-center gap-2 justify-content-end">
                        {frameToDelete ? (
                          <span className="btn btn-secondary action-btn">
                            View
                          </span>
                        ) : (
                          <Link
                            className="btn btn-primary action-btn"
                            to={`/${item.design_id}/${item.frame_id}`}
                          >
                            View
                          </Link>
                        )}
                        {/* rendering as a span element when disabled - tooltips or other interactive elements don't work in disabled elements */}
                        {item.original_frame_id ? (
                          <span
                            className="btn btn-secondary action-btn faded"
                            title="Make duplicate"
                            data-tooltip-id={`dup_${item._id}`}
                            data-tooltip-content="Cannot duplicate from a duplicate"
                          >
                            <BsCopy />
                            <Tooltip id={`dup_${item._id}`} />
                          </span>
                        ) : (
                          <button
                            className="btn btn-secondary action-btn"
                            onClick={() => handleDuplicateClick(item)}
                            disabled={
                              // restrict from duplicating if the frame is already a orphan
                              isDuplicateLoading || !!item.original_frame_id
                            }
                            title="Make duplicate"
                            data-tooltip-id={`dup_${item._id}`}
                            data-tooltip-content={
                              item.original_frame_id
                                ? "Cannot duplicate from a duplicate"
                                : "Make duplicate"
                            }
                          >
                            <BsCopy />
                            <Tooltip id={`dup_${item._id}`} />
                          </button>
                        )}
                        {userData && checkIsSuperAdmin(userData?.role) && (
                          <button
                            className="btn btn-secondary action-btn"
                            onClick={() => {
                              setFrameToDelete({
                                design_id: item.design_id,
                                frame_id: item.frame_id,
                                frame_name: item.frame_name,
                                image_url: item.image_url,
                                is_updated: item.is_updated,
                              });
                            }}
                            disabled={isDeleteLoading}
                            title="Delete"
                            data-tooltip-id={`del_${item._id}`}
                            data-tooltip-content="Delete"
                          >
                            <BsFillXCircleFill />
                            <Tooltip id={`del_${item._id}`} />
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      {!!frameToUpdateMetadata && (
        <FrameMetadataEditorModal
          frameUpdate={frameToUpdateMetadata}
          isForDuplicate={!!frameToDuplicate}
          oldName={frameToUpdateMetadata.oldName}
          onStringChange={(newName) => {
            if (!frameToUpdateMetadata) return;
            setFrameToUpdateMetadata((prev) => ({
              design_id: prev!.design_id,
              frame_id: prev!.frame_id,
              oldName: prev!.oldName,
              newName,
            }));
          }}
          onCancel={() => setFrameToUpdateMetadata(null)}
          onSave={handleFrameMetadataUpdate}
        />
      )}
      {!!frameToDelete && (
        <PageDeleteConfirmationModal
          frameName={frameToDelete.frame_name}
          onCancel={() => setFrameToDelete(null)}
          onDelete={() => deleteFrame(frameToDelete)}
          isLoading={isDeleteLoading}
          isUpdatePending={frameToDelete.is_updated}
        />
      )}
    </div>
  );
}

export default SingleDesignView;
