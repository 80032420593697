import React, { useEffect, useRef } from "react";
import { TextEditorModalProps } from "../../types/modal";
import { countLines } from "../../utils/common-utils";
import { PiKeyReturnLight } from "react-icons/pi";
import { MdOutlineKeyboardCapslock } from "react-icons/md";

export default function TextEditorModal(props: Readonly<TextEditorModalProps>) {
  const isWarning = props.text.length >= props.characterWarningLength;
  const isError = props.text.length === props.characterBlockLength;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  /**
   * Handle Enter key press & Ctrl + Enter key press
   * Ctrl + Enter - Save the text & add a new line
   * Enter - Save the text
   * @param e - Keyboard event
   * @returns void
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (e.ctrlKey) {
        // Allow new line when Ctrl + Enter is pressed
        e.preventDefault(); // Prevent default behavior of Enter key
        props.handleSave(); // Call the save function
      } else {
        return; // Do nothing, let the default behavior occur
      }
    } else if (e.key === 'Escape') {
      props.onClose();
    }
  };

  useEffect(() => {
    if (props.open && textareaRef.current) {
      textareaRef.current.focus(); // Focus the textarea when the modal opens
    }
  }, [props.open]);

  return (
    <div
      className={`${"modal"} ${props.open ? "display-block" : "display-none"}`}
    >
      <div className="modal-main">
        <div className="modal-head">
          <h2>Text Update</h2>
        </div>
        <div className="modal-body d-block">
          <textarea
            value={props.text}
            onChange={(e) => props.onStringChange(e.target.value)}
            className="text-field"
            rows={Math.max(countLines(props.text) + 2, 5)}
            maxLength={props.characterBlockLength}
            onKeyDown={handleKeyDown}
            ref={textareaRef}
          />
          <div className="text-end">
            <span className={isWarning ? "warning" : isError ? "error" : ""}>
              {props.text.length}/{props.characterBlockLength - 1}
            </span>
          </div>
        </div>
        {!isError && isWarning && (
          <div className="modal-error">
            <span className="warning">
              Warning: This character limit might break the design!
            </span>
          </div>
        )}
        {isError && (
          <div className="modal-error">
            <span className="error">Error: Character limit exceeded!</span>
          </div>
        )}
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-danger"
            onClick={props.onClose}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={isError}
            onClick={props.handleSave}
            title="Ctrl + Enter to save"
          >
            <span className="icon-wrapper">
              <span className="btn-text">Save</span>
              <MdOutlineKeyboardCapslock />
              <PiKeyReturnLight />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
