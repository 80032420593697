import React, { useEffect, useState } from "react";
import { AuthContext } from "../context/auth-context";
import { UserData } from "../types/auth-data";
import {
  getAuthHeaderValue,
  removeToken,
  validateToken,
} from "../utils/auth-utils";
import axiosApi from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH_LOGIN } from "../constants";

/**
 * Wrapper component for providing auth context to the child components.
 * @param param0
 * @returns
 */
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isPending, setIsPending] = useState(true);
  const navigate = useNavigate();

  // set the context value on component remount
  // check if the token is available & valid
  useEffect(() => {
    const hydrateAuthState = async () => {
      const { userData, token } = await validateToken();

      if (userData) {
        setUserData(userData);

        // set auth header to api instance
        axiosApi.defaults.headers.common["Authorization"] =
          getAuthHeaderValue(token);
      }
    };

    hydrateAuthState()
      .catch((err) => {
        console.error(err);
        removeToken();
        navigate(PATH_LOGIN);
      })
      .finally(() => setIsPending(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ userData, setAuth: setUserData, isPending, setIsPending }}
    >
      <>
        {isPending && (
          <div className="loader-overlay">
            <div className="spinner-border text-primary" role="status" />
          </div>
        )}
        {children}
      </>
    </AuthContext.Provider>
  );
};
