const config = {
  api: {
    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    API_PREFIX: process.env.REACT_APP_API_PREFIX,
  },
  JWT_SECRET: process.env.REACT_APP_JWT_SECRET,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...config,
};
