import React from "react";
import { FaFigma } from "react-icons/fa";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { PATH_LOGIN } from "../constants";
import { useAuth } from "../hooks/useAuth";
import { PathParameters } from "../types/path-parameters";
import { getRoleName, removeToken } from "../utils/auth-utils";

const Navbar: React.FC = () => {
  const { userData, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<PathParameters>();

  const handleLogout = () => {
    removeToken();
    setAuth(null);
    navigate(PATH_LOGIN);
    toast.info("Successfully logged out!");
  };

  // Check if the current path is a dynamic segment for SingleDesignView or SingleFrameView
  // Determine the target route for the back button
  const getBackNavigationPath = () => {
    if (
      location.pathname.startsWith(`/${params.design_id}/${params.frame_id}`)
    ) {
      // User is on SingleFrameView
      return { path: `/${params.design_id}`, name: "Page list" }; // Navigate to SingleDesignView
    } else if (location.pathname.startsWith(`/${params.design_id}`)) {
      // User is on SingleDesignView
      return { path: "/", name: "Dashboard" }; // Navigate to Dashboard
    }
    return null; // No back navigation needed
  };

  const backNavigationPath = getBackNavigationPath();

  return (
    <nav className="navbar">
      <div className="navbar-brand-wrapper">
        <Link className="navbar-brand" to="/">
          <FaFigma className="brand-icon" />
          <span className="brand-text">Figma Press</span>
        </Link>
        {backNavigationPath && (
          <button
            className="back-button"
            onClick={() => navigate(backNavigationPath.path)}
            data-tooltip-id="back_btn"
            data-tooltip-content={`Go back to ${backNavigationPath.name}`}
          >
            <IoArrowBackCircleSharp className="back-btn-icn" />
            <Tooltip id="back_btn" />
          </button>
        )}
      </div>
      <div className="navbar-user">
        {userData && (
          <>
            <span
              className="user-email"
              data-tooltip-id="username"
              data-tooltip-content={getRoleName(userData.role)}
            >
              <Tooltip id="username" />
              {userData.email}
            </span>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
