import React, { useRef } from "react";
import { SvgViewProps } from "../types/view";
import WebFont from "webfontloader";
import { FontFamily, TextNode } from "../types";

/**
 * Load SVG image
 * @param props
 * @returns
 */
export default function SvgView(props: Readonly<SvgViewProps>) {
  const loadedFontFamilies = useRef<Array<FontFamily>>([]);

  const loadFont = (fontFamily: FontFamily) => {
    // trying google fonts as it is freely available
    // check if this font family is already loaded
    // if so skip
    if (
      loadedFontFamilies.current.find(
        (loadedFontFamily) =>
          loadedFontFamily.family === fontFamily.family &&
          loadedFontFamily.style === fontFamily.style &&
          loadedFontFamily.weight === fontFamily.weight
      )
    ) {
      return;
    }

    // add this font to loaded fonts var
    loadedFontFamilies.current.push(fontFamily);

    // construct the font family string as per Google Fonts API - https://developers.google.com/fonts/docs/getting_started#Syntax
    // load fonts with both style string  & numeric weight
    WebFont.load({
      google: {
        families: [
          `${fontFamily.family}:${fontFamily.style.toLowerCase()}`,
          `${fontFamily.family}:${fontFamily.weight}`,
        ],
      },
    });
  };

  /**
   * Calculate proportionally scaled down font-size for a textnode
   * based on its max char count
   * @param textNode
   */
  const calcFontSize = (textNode: TextNode) => {
    return textNode.characters.length >= textNode.characterWarningLength / 2
      ? textNode.fontSize / 2
      : textNode.fontSize;
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${props.data.frame_width} ${props.data.frame_height} `}
      width={props.data.frame_width}
      height={props.data.frame_height}
      style={{ width: "100%", height: "100%" }}
    >
      <image
        key={props.imageUrl}
        href={props.imageUrl}
        width="100%"
        height="100%"
        onLoad={() => props.setIsImageLoad(true)}
      ></image>
      {props.data.text_nodes.map((node) => {
        const updatedNode = props.updatedTextNodes.find(
          (n) => n.id === node.id
        );

        loadFont({
          family: node.fontFamily,
          style: node.fontStyle,
          weight: node.fontWeight,
        });

        // create overlay elements on top of each text element of the image
        return (
          <React.Fragment key={node.id}>
            <rect
              key={node.id}
              id={node.id}
              data-text={node.id}
              x={node.x}
              y={node.y}
              width={node.width}
              height={node.height}
              style={{
                fill: node.is_updated || updatedNode ? "white" : "transparent",
                cursor: "pointer",
              }}
              onClick={() => props.handleTextClick(node)}
            >
              <title>{node.characters}</title>
            </rect>
            {props.isImageLoad && (node.is_updated || updatedNode) && (
              <foreignObject
                x={node.x}
                y={node.y}
                width={node.width}
                height={node.height}
              >
                <div
                  className="figma-press-text"
                  style={{
                    fontFamily: node.fontFamily,
                    fontStyle: node.fontStyle,
                    fontSize: calcFontSize(node),
                    fontWeight: node.fontWeight,
                    letterSpacing: node.letterSpacing,
                    lineHeight: `${node.lineHeight}%`,
                  }}
                  onClick={() => props.handleTextClick(node)}
                >
                  {node.characters}
                </div>
              </foreignObject>
            )}
          </React.Fragment>
        );
      })}
    </svg>
  );
}
