import axiosApi from ".";
import {
  API_SET_PW,
  API_USER_ADD_UPDATE_DESIGN,
  API_USER_LOGIN,
  API_USER_USERS_OF_DESIGN,
  API_USER_USERS_OF_FRAME,
  USER_ROLE,
  USER_STATUS,
} from "../constants";
import {
  AddUpdateUserDesignAccessRequestData,
  AddUpdateUserFrameAccessRequestData,
  APIResponse,
  GetUsersGrantedForDesignRequestData,
  GetUsersGrantedForFrameRequestData,
  SetPasswordRequestData,
  UserGrantedForDesign,
  UserGrantedForFrame,
} from "../types";
import { UserLoginRequestData } from "../types/auth-data";

export const login = async (data: UserLoginRequestData) => {
  return axiosApi.post<APIResponse<{ token: string }>>(API_USER_LOGIN, {
    ...data,
  });
};

export const setNewPassword = async (data: SetPasswordRequestData) => {
  return axiosApi.post<APIResponse<{ status: USER_STATUS }>>(API_SET_PW, {
    ...data,
  });
};

export const updateDesignAccessForUser = async (
  data: AddUpdateUserDesignAccessRequestData
) => {
  // API handles multiple design ids in same request for a single user.
  const dataToSend = { ...data, design_ids: [data.design_id] };
  return axiosApi.post<
    APIResponse<{ email: string; role: USER_ROLE; password?: string }>
  >(API_USER_ADD_UPDATE_DESIGN, {
    ...dataToSend,
  });
};

export const addUpdateFrameAccessForUser = async (
  data: AddUpdateUserFrameAccessRequestData
) => {
  // API handles multiple frame ids in same request for a single user.
  const dataToSend = { ...data, frame_ids: [data.frame_id] };
  return axiosApi.post<
    APIResponse<{ email: string; role: USER_ROLE; password?: string }>
  >(API_USER_ADD_UPDATE_DESIGN, {
    ...dataToSend,
  });
};

export const getUsersOfDesign = async (
  data: GetUsersGrantedForDesignRequestData
) => {
  return axiosApi.post<APIResponse<UserGrantedForDesign[]>>(
    API_USER_USERS_OF_DESIGN,
    {
      ...data,
    }
  );
};

export const getUsersOfFrame = async (
  data: GetUsersGrantedForFrameRequestData
) => {
  return axiosApi.post<APIResponse<UserGrantedForFrame[]>>(
    API_USER_USERS_OF_FRAME,
    {
      ...data,
    }
  );
};
