/**
 * Utilities for Authentication
 */

import * as jose from "jose";
import config from "../config";
import { TOKEN_KEY, USER_ROLE } from "../constants";
import { UserData } from "../types/auth-data";

const _getJwtSecret = () => {
  const jwtSecret = config.JWT_SECRET;
  if (jwtSecret) {
    return new TextEncoder().encode(jwtSecret);
  }
  return null;
};

/**
 * Persist the auth-token in client storage & return the token data.
 *
 * @param token
 * @returns
 */
export async function persistToken(token: string) {
  return new Promise<{ userData: UserData; token: string }>((res, rej) => {
    const jwtSecret = config.JWT_SECRET;

    if (!token || !jwtSecret) {
      rej(new Error("Invalid credentials."));
    }

    validateToken(token)
      .then((data) => {
        localStorage.setItem(TOKEN_KEY, token);
        res(data);
      })
      .catch((err) => rej(new Error(err)));
  });
}

/**
 * Remove the token from the client storage.
 */
export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

/**
 * Get the token as is.
 * @returns
 */
const _getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

/**
 * Create the authorization header value.
 * @returns
 */
export const getAuthHeaderValue = (token: string) => {
  return `Bearer ${token}`;
};

/**
 * Validate the token & return the decoded & the token itself.
 * If validation fails, this will throw error.
 *
 * @param token
 * @param jwtSecret
 * @returns
 */
export const validateToken = async (token?: string | null) => {
  token = token ?? _getToken();
  const jwtSecret = _getJwtSecret();

  if (token && jwtSecret) {
    const decoded = await jose.jwtVerify<UserData>(token, jwtSecret);
    return { userData: decoded.payload, token };
  }

  throw new Error("Invalid credentials.");
};

/**
 * Returns true if the provided user has Admin privileges.
 * @param userRole
 * @returns
 */
export const checkIsAdmin = (userRole: USER_ROLE) =>
  userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.SUPER_ADMIN;

/**
 * Returns true if the provided user has SuperAdmin role.
 *
 * @param userRole
 * @returns
 */
export const checkIsSuperAdmin = (userRole: USER_ROLE) => {
  return userRole === USER_ROLE.SUPER_ADMIN;
};

/**
 * Return a string which can be displayed as the user role.
 * @param userRole
 * @returns
 */
export const formatRoleName = (userRole: USER_ROLE) =>
  userRole.replaceAll("_", " ");

/**
 * Get the role as a readable name.
 * @param userRole
 * @returns
 */
export const getRoleName = (userRole: USER_ROLE) => {
  return userRole.replaceAll("_", " ");
};
