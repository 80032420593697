import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ImageAnnotator, useImageAnnotator } from "react-image-label";
import figmaApi from "../api/figmaApi";
import {
  TextNode,
  FigmaFrameIdentifier,
  UpdateFigmaFrameRequestData,
  FigmaData,
} from "../types";
import TextEditorModal from "../components/modals/TextEditorModal";
import SvgView from "../components/SvgView";
import { PathParameters } from "../types/path-parameters";

/**
 * Renders the image to edit the text elements on for a frame.
 * @returns
 */
function SingleFrameView() {
  const { design_id, frame_id } = useParams<PathParameters>();

  const navigate = useNavigate();

  const hasFetchedData = useRef(false);
  const [data, setData] = useState<FigmaData | null>(null);
  const [selectedText, setSelectedText] = useState<string>("");
  const [selectedTextId, setSelectedTextId] = useState<string>("");
  const [characterWarningLength, setCharacterWarningLength] =
    useState<number>(0);
  const [characterBlockLength, setCharacterBlockLength] = useState<number>(0);

  const [showTextEditorModal, setShowTextEditorModal] =
    useState<boolean>(false);
  const [updatedTextNodes, setUpdatedTextNodes] = useState<Array<TextNode>>([]);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isImageLoad, setIsImageLoad] = useState<boolean>(false);

  const { setHandles, annotator } = useImageAnnotator();
  const [isContentUpdateMode, setIsContentUpdateMode] = useState<boolean>(true);
  const [isImageUpdateMode, setIsImageUpdateMode] = useState<boolean>(false);
  const [isClientMode, setIsClientMode] = useState<boolean>(false);
  const [isConfigurationMode, setIsConfigurationtMode] =
    useState<boolean>(true);
  const [showImageDataModal, setShowImageDataModal] = useState<boolean>(false);

  const [imageBound, setImageBound] = useState<any>();
  const [shapes, setShapes] = useState<any>([]);

  const toggleTextEditorModal = () => {
    setShowTextEditorModal(!showTextEditorModal);
  };

  const toggleImageDataModal = () => {
    setShowImageDataModal(!showImageDataModal);
  };

  const onStringChange = (value: string) => {
    setSelectedText(value);
  };

  const handleTextClick = (node: TextNode) => {
    setSelectedText(node.characters);
    setSelectedTextId(node.id);
    setCharacterWarningLength(node.characterWarningLength);
    setCharacterBlockLength(node.characterBlockLength);
    toggleTextEditorModal();
  };

  const updateFigmaFrameContent = async (data: UpdateFigmaFrameRequestData) => {
    try {
      const response = await figmaApi.updateFigmaFrameContent(data);
      if (response.status !== 200) {
        toast(response.data.message, { type: "error" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = () => {
    let editedNode: any = {};
    if (!data) return;

    const updatedTextNodes = data.text_nodes.map((node) => {
      if (node.id === selectedTextId && node.characters !== selectedText) {
        editedNode = { ...node, characters: selectedText };
        setUpdatedTextNodes((prevNodes) => [...prevNodes, editedNode]);
        return { ...node, characters: selectedText };
      }
      return node;
    });
    setData({ ...data, text_nodes: updatedTextNodes });

    updateFigmaFrameContent({
      design_id: data.design_id,
      frame_id: data.frame_id,
      text_nodes: [editedNode],
    });
    toggleTextEditorModal();
  };

  const getFigmaFrameContent = async (data: FigmaFrameIdentifier) => {
    try {
      const response = await figmaApi.getFigmaFrame(data);

      if (response.data.statusCode !== 200) {
        throw new Error("No Figma data returned");
      }

      setData(response.data.data);
      setShapes(response.data.data.image_nodes);
      setImageUrl(`${response.data.data.image_url}?${new Date().getTime()}`);
    } catch (error) {
      console.error(error);

      if (error instanceof Error) {
        toast(error.message, { type: "error" });
      }
      navigate(`/${design_id}`);
    }
  };

  useEffect(() => {
    if (!design_id || !frame_id || hasFetchedData.current) return;
    hasFetchedData.current = true;

    setIsImageLoad(false);
    getFigmaFrameContent({ design_id, frame_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [design_id, frame_id]);

  // Image update functions TODO: impl

  // const generatePageImages = async (data: GenerateFigmaFrameImageData) => {
  //   try {
  //     const response = await figmaApi.generateFrameImages(data);
  //     if (response.status !== 200) {
  //       toast(response.data.message, { type: "error" });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleSaveImageBounding = () => {
  //   annotator?.edit(imageBound.id);
  //   annotator?.updateCategories(imageBound.id, imageBound.categories);
  //   updateShapes();
  //   toggleImageDataModal();
  // };

  // const handleDeleteImageBounding = (id: number) => {
  //   annotator?.delete(id);
  //   updateShapes();
  //   toggleImageDataModal();
  // };

  // const updateShapes = () => {
  //   const updatedShapes = annotator?.getShapes().map((shape) => {
  //     if (shape.id === imageBound.id) {
  //       return {
  //         ...shape,
  //         categories: imageBound.categories
  //           ? imageBound.categories
  //           : [{ isBackgoundImage: false }],
  //       };
  //     }
  //     return shape;
  //   });

  //   setShapes(updatedShapes);
  //   generatePageImages({ design_id, frame_id, image_nodes: updatedShapes });
  // };

  // const selectedIsBackground = (items: any) => {
  //   imageBound.categories = [items];
  //   setImageBound(imageBound);
  // };

  // const convertPointsToSVG = (
  //   pointsArray: any,
  //   scaleX: number,
  //   scaleY: number
  // ) => {
  //   return pointsArray
  //     .map((point: any) => {
  //       const x = point[0] * scaleX;
  //       const y = point[1] * scaleY;
  //       return `${x},${y}`;
  //     })
  //     .join(" ");
  // };

  return (
    <div className="page-content-wrapper frame-view">
      {/* TODO : Will continute this with the image edit feature */}
      {/* {isImageLoad &&
                <>
                    <div className="btn-container page-button">
                        <button type="button" className="btn btn-primary" onClick={() => {
                            setIsContentUpdateMode(true);
                            setIsImageUpdateMode(false);
                            setIsClientMode(false);
                            setIsConfigurationtMode(true);
                        }}>
                            Content Update
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => {
                            setIsContentUpdateMode(false);
                            setIsImageUpdateMode(true);
                            setIsClientMode(false);
                            setIsConfigurationtMode(true);
                        }}>
                            Image Update
                        </button>
                        <h3 className='page-heading'>{isContentUpdateMode ? 'Content' : 'Image'} Update {isImageUpdateMode && (isClientMode ? '| Client Mode' : '| Configuration Mode')}</h3>
                    </div>

                    {isImageUpdateMode &&
                        <div className="btn-container page-button">
                            <button type="button" className="btn btn-info" onClick={() => {
                                setIsClientMode(true);
                                setIsConfigurationtMode(false);
                                annotator?.stop();
                            }}>
                                Client Update
                            </button>
                            <button type="button" className="btn btn-info" onClick={() => {
                                setIsClientMode(false);
                                setIsConfigurationtMode(true);
                            }}>
                                Configuration Update
                            </button>
                        </div>
                    }
                </>

            } */}
      {!isImageLoad && (
        <div className="loader-overlay">
          <div className="spinner-border text-primary" role="status" />
        </div>
      )}
      {data && (
        <>
          {isContentUpdateMode && (
            <SvgView
              data={data}
              imageUrl={imageUrl}
              updatedTextNodes={updatedTextNodes}
              handleTextClick={handleTextClick}
              isImageLoad={isImageLoad}
              setIsImageLoad={setIsImageLoad}
            />
          )}
          {isImageUpdateMode && isConfigurationMode && (
            <div className="image-container">
              <ImageAnnotator
                setHandles={setHandles}
                naturalSize={false}
                imageUrl={imageUrl}
                shapes={shapes}
                onAdded={(shape) => {
                  setImageBound(shape);
                  toggleImageDataModal();
                }}
                onContextMenu={(shape) => {
                  setImageBound(shape);
                  toggleImageDataModal();
                }}
                onReady={(annotator) => {
                  annotator.drawRectangle();
                  setIsImageLoad(true);
                }}
              />
            </div>
          )}
          {isImageUpdateMode && isClientMode && (
            <div className="image-container">
              <ImageAnnotator
                setHandles={setHandles}
                naturalSize={false}
                imageUrl={imageUrl}
                shapes={shapes}
                onSelected={() => alert("hi")}
              />
            </div>
          )}
        </>
      )}
      <TextEditorModal
        open={showTextEditorModal}
        onClose={toggleTextEditorModal}
        text={selectedText}
        characterWarningLength={characterWarningLength}
        characterBlockLength={characterBlockLength}
        onStringChange={onStringChange}
        handleSave={handleSave}
      />
      {/* <ImageEditorModal
        open={showImageDataModal}
        onDelete={() => {
          handleDeleteImageBounding(imageBound.id);
        }}
        onSave={() => {
          handleSaveImageBounding();
        }}
        selectedIsBackground={(item: any) => {
          selectedIsBackground(item);
        }}
        offset={imageBound?.getCenterWithOffset()}
        id={imageBound?.id}
        items={shapes}
      /> */}
    </div>
  );
}

export default SingleFrameView;
