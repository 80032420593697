import { useEffect, useState } from "react";
import { FaUserCircle, FaUserClock, FaUserTimes } from "react-icons/fa";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { getUsersOfDesign, updateDesignAccessForUser } from "../../api/userApi";
import { USER_ROLE, USER_STATUS } from "../../constants";
import { useAuth } from "../../hooks/useAuth";
import { UserGrantedForDesign } from "../../types";
import { UserRoleUpdate } from "../../types/auth-data";
import { DesignInvitationModalProps } from "../../types/modal";
import { checkIsSuperAdmin, formatRoleName } from "../../utils/auth-utils";
import { BsFillXCircleFill } from "react-icons/bs";
import { IoIosRemoveCircle } from "react-icons/io";

/**
 * Renders a modal to show currently invited users for this design.
 * Ability to change user role of each invited user.
 * Can revoke access for invited users.
 *
 * Show each user in this design file along with their role.
 * Provide admins ability to revoke access of users.*
 * Provide admins ability to change user role.*
 *
 * @param props
 * @returns
 */
export default function DesignUserManagementModal(
  props: Readonly<DesignInvitationModalProps>
) {
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUsersList] = useState<UserGrantedForDesign[]>([]);
  // keep list of role updates
  const [userRoles, setUserRoles] = useState<UserRoleUpdate[]>([]);

  const { userData } = useAuth();

  // load the users who already has access for this design
  useEffect(() => {
    const fetchUsersOfDesign = async () => {
      setIsLoading(true);
      try {
        const response = await getUsersOfDesign({ design_id: props.design_id });

        if (response.data.statusCode !== 200) {
          throw new Error(response.data.message);
        }

        setUsersList(response.data.data);
        setUserRoles(
          response.data.data.map((user) => ({ id: user._id, role: user.role }))
        );
      } catch (error) {
        console.error(error);
        toast.error("Could not load users who has access to this design.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsersOfDesign();
  }, [props.design_id]);

  const getRoleStateForUser = (user: UserGrantedForDesign) => {
    const stateUserRole = userRoles.find(
      (userRoleRef) => userRoleRef.id === user._id
    );
    return stateUserRole ? stateUserRole.role : USER_ROLE.USER;
  };

  const updateRoleStateForUser = (
    user: UserGrantedForDesign,
    newRoleForUser: USER_ROLE
  ) => {
    setUserRoles((prev) => {
      const updatedUserRoleList = [...prev];
      const roleUpdate = updatedUserRoleList.find(
        (roleUpdateRef) => roleUpdateRef.id === user._id
      );
      if (roleUpdate) {
        roleUpdate.role = newRoleForUser;
      }
      return updatedUserRoleList;
    });
  };

  const getIsUserRoleChanged = (user: UserGrantedForDesign) => {
    const newRoleForUser = userRoles.find(
      (userRoleRef) => userRoleRef.id === user._id
    )?.role;
    return { isRoleChanged: user.role !== newRoleForUser, newRoleForUser };
  };

  const updateUserRole = async (user: UserGrantedForDesign) => {
    try {
      // validate role - if the role has been changed only update
      const { newRoleForUser, isRoleChanged } = getIsUserRoleChanged(user);
      if (!newRoleForUser || !isRoleChanged) {
        console.log("User' role not changed.");
        return;
      }

      setIsLoading(true);
      const response = await updateDesignAccessForUser({
        email: user.email,
        design_id: props.design_id,
        role: newRoleForUser,
      });

      // update the userlist with new userrole for the user
      setUsersList((prev) => {
        const updatedUserList = [...prev];
        const userToUpdate = updatedUserList.find(
          (userListRef) => userListRef._id === user._id
        );
        if (userToUpdate) {
          userToUpdate.role = newRoleForUser;
        }
        return updatedUserList;
      });

      if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
      }

      toast.success("User access updated.");
    } catch (error) {
      console.error(error);
      toast.error("Error while inviting the user");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUser = async (user: UserGrantedForDesign) => {
    toast.info("Not yet supported.");
    // try {
    //   const response = await
    // } catch (error) {
    //   console.error(error);
    //   toast.error("Could not delete the user");
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const revokeUserAccess = async (user: UserGrantedForDesign) => {
    toast.info("Not yet supported.");
    // try {
    //   const response = await
    // } catch (error) {
    //   console.error(error);
    //   toast.error("Could not delete the user");
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const getUserIcon = (userId: string, status: USER_STATUS) => {
    switch (status) {
      case USER_STATUS.ACTIVE:
        return (
          <>
            <FaUserCircle
              data-tooltip-id={`user_icon_${userId}`}
              data-tooltip-content="Active User"
            />
            <Tooltip id={`user_icon_${userId}`} />
          </>
        );
      case USER_STATUS.PENDING:
        return (
          <>
            <FaUserClock
              data-tooltip-id={`user_icon_${userId}`}
              data-tooltip-content="Pending password change"
            />
            <Tooltip id={`user_icon_${userId}`} />
          </>
        );
      case USER_STATUS.DELETED:
        return (
          <>
            <FaUserTimes
              data-tooltip-id={`user_icon_${userId}`}
              data-tooltip-content="Deleted User"
            />
            <Tooltip id={`user_icon_${userId}`} />
          </>
        );
    }
  };

  return (
    <div className="modal display-block">
      <div className="modal-main">
        <div className="modal-head">
          <h2>
            Update users of <em>{props.design_name}</em>
          </h2>
        </div>
        <div className="modal-body d-block position-relative">
          {isLoading && (
            <div className="loader-overlay position-absolute">
              <div className="spinner-border text-primary" role="status" />
            </div>
          )}
          <div className="form-wrapper">
            <ul className="list-group list-group-flush">
              {/* render each user as a line item with CTA s for updating access */}
              {usersList.map((user) => (
                <li
                  key={user._id}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div className="frame-name-wrapper align-items-center d-flex">
                    {getUserIcon(user._id, user.status)}
                    <span className="ms-1">{user.email}</span>
                  </div>
                  {/* CTAs */}
                  <div className="action-wrapper d-flex gap-1 align-items-center justify-content-end ms-2">
                    <div className="role-selector d-flex align-items-center">
                      <select
                        name="frame-name"
                        className="form-control me-1"
                        value={getRoleStateForUser(user)}
                        disabled={isLoading}
                        onChange={(e) =>
                          updateRoleStateForUser(
                            user,
                            e.target.value as USER_ROLE
                          )
                        }
                      >
                        {userData?.can_assign_roles.map((role) => (
                          <option key={role} value={role}>
                            {formatRoleName(role)}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        className="btn btn-primary me-0"
                        onClick={() => updateUserRole(user)}
                        disabled={
                          isLoading || !getIsUserRoleChanged(user).isRoleChanged
                        }
                        data-tooltip-id={`update_role_${user._id}`}
                        data-tooltip-content="Update role"
                      >
                        <IoCheckmarkCircleSharp />
                        <Tooltip id={`update_role_${user._id}`} />
                      </button>
                    </div>
                    {userData && checkIsSuperAdmin(userData.role) && (
                      <button
                        type="button"
                        className="btn btn-danger me-0"
                        onClick={() => deleteUser(user)}
                        disabled={isLoading}
                        data-tooltip-id={`del_${user._id}`}
                        data-tooltip-content="Delete"
                      >
                        <BsFillXCircleFill />
                        <Tooltip id={`del_${user._id}`} />
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-danger me-0"
                      onClick={() => revokeUserAccess(user)}
                      disabled={isLoading}
                      data-tooltip-id={`revoke_${user._id}`}
                      data-tooltip-content="Revoke"
                    >
                      <IoIosRemoveCircle />
                      <Tooltip id={`revoke_${user._id}`} />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={props.onCancel}
            disabled={isLoading}
          >
            {isLoading ? "Updading.." : "Close"}
          </button>
        </div>
      </div>
    </div>
  );
}
