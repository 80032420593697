import { useContext } from "react";
import { AuthContext } from "../context/auth-context";

/**
 * Get the auth context.
 *
 * @returns
 */
export function useAuth() {
  const authContext = useContext(AuthContext);
  if (authContext === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return authContext;
}
