import { FormEvent, useCallback, useEffect, useState } from "react";
import { FaFigma } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosApi from "../api";
import { login } from "../api/userApi";
import PasswordModal from "../components/modals/PasswordModal";
import { USER_STATUS } from "../constants";
import { useAuth } from "../hooks/useAuth";
import { UserLoginRequestData } from "../types/auth-data";
import { getAuthHeaderValue, persistToken } from "../utils/auth-utils";
import { validateEmail, validatePassword } from "../utils/validator-utils";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, setAuth, isPending } = useAuth();
  const [isShowPasswordModal, setIsShowPasswordModal] = useState(false);

  const navigateToPathOrDashboard = useCallback(() => {
    // Redirect to the page user tried to access, or to dashboard
    const origin = (location.state as any)?.from?.pathname || "/";
    navigate(origin);
  }, [location, navigate]);

  // check auth status & if already logged in
  // redirect to dashboard
  // if first login, open password set modal
  useEffect(() => {
    if (userData && userData.status === USER_STATUS.ACTIVE) {
      navigateToPathOrDashboard();
    } else if (userData && userData.status !== USER_STATUS.PENDING) {
      setIsShowPasswordModal(true);
    }
  }, [navigateToPathOrDashboard, userData]);

  const loginUser = async (loginRequestData: UserLoginRequestData) => {
    try {
      const response = await login(loginRequestData);

      if (response.data.statusCode === 200) {
        const { userData } = await persistToken(response.data.data.token);
        // set auth context
        setAuth(userData);

        // set auth header to api instance
        axiosApi.defaults.headers.common["Authorization"] = getAuthHeaderValue(
          response.data.data.token
        );

        // if user is logging in for the first time - require new password
        if (userData.status === USER_STATUS.PENDING) {
          setIsShowPasswordModal(true);

          // do not redirect to dashboard
          return false;
        } else {
          return true;
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while trying to log you in.");
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      let isValid = true;

      // Validate email
      if (!validateEmail(email)) {
        setEmailError("Please enter a valid email address");
        isValid = false;
      } else {
        setEmailError("");
      }

      // Validate password
      const passwordValidation = validatePassword(password);
      if (!passwordValidation.isValid) {
        setPasswordError(passwordValidation.error);
        isValid = false;
      } else {
        setPasswordError("");
      }

      if (isValid) {
        const shouldRedirect = await loginUser({ email, password });

        // Redirect to the page user tried to access, or to dashboard
        if (shouldRedirect) {
          navigateToPathOrDashboard();
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error while logging you in.");
    } finally {
      setIsLoading(false);
    }
  };

  const showLoader = isLoading || (!userData && isPending);

  return (
    <div className="page-content-wrapper d-grid">
      {showLoader && (
        <div className="loader-overlay">
          <div className="spinner-border text-primary" role="status" />
        </div>
      )}
      <div className="centered-container login-wrapper m-0">
        <div className="login-container">
          <h2>
            <FaFigma className="brand-icon" />
            Figma Press | Eight25
          </h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {emailError && <p className="error">{emailError}</p>}
            </div>
            <div>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {passwordError && <p className="error">{passwordError}</p>}
            </div>
            <button
              type="submit"
              disabled={!email || !password || isShowPasswordModal}
            >
              Login
            </button>
          </form>
        </div>
      </div>
      {isShowPasswordModal && (
        <PasswordModal onCancel={() => setIsShowPasswordModal(false)} />
      )}
    </div>
  );
}

export default Login;
