export const API_FIGMA_CONTENT = "figma/content";
export const API_FIGMA_PROJECT = "figma/project";
export const API_FIGMA_UPDATE = "figma/update";
export const API_FIGMA_GENERATE_PAGE_IMAGES = "figma/generatePageImages";
export const API_FIGMA_DELETE = "figma/delete";
export const API_FIGMA_DUPLICATE = "figma/duplicate";
export const API_FIGMA_FRAME_UPDATE = "figma/update-frame-metadata";
export const API_FIGMA_GET_DESIGNS = "figma/designs";

export const API_USER_LOGIN = "user/login";
export const API_USER_ADD_UPDATE_DESIGN = "user/add-update-design";
export const API_SET_PW = "user/set-password";
export const API_USER_REVOKE = "user/revoke";
export const API_USER_DELETE = "user/delete";
export const API_USER_RESET = "user/reset";
export const API_USER_USERS_OF_DESIGN = "user/users-of-design";
export const API_USER_USERS_OF_FRAME = "user/users-of-frame";
export const API_ADD_UPDATE_USER_FRAME = "user/add-update-frame";

export const PATH_PARAM_DESIGN_ID = "design_id";
export const PATH_PARAM_FRAME_ID = "frame_id";
export const PATH_LOGIN = "/login";

export const TOKEN_KEY = "user_token";

export enum USER_ROLE {
  /**
   * - View all designs
   * - Revoke/Grant access to Super Admins, Admins & Users
   * - Delete Admins & users
   * - Delete frames from designs
   */
  SUPER_ADMIN = "Super_Administrator",
  /**
   * - View all designs
   * - Revoke/Grant access to Admins & Users
   */
  ADMIN = "Administrator",
  /**
   * - View granted designs
   */
  USER = "User",
}

export enum USER_STATUS {
  /**
   * User is active & can access the system.
   */
  ACTIVE = "active",
  /**
   * Password change is required before first login.
   */
  PENDING = "pending",
  /**
   * User access has been revoked & no longer can access the system.
   */
  DELETED = "deleted",
}
