import axiosApi from ".";
import {
  API_FIGMA_CONTENT,
  API_FIGMA_DELETE,
  API_FIGMA_DUPLICATE,
  API_FIGMA_FRAME_UPDATE,
  API_FIGMA_GENERATE_PAGE_IMAGES,
  API_FIGMA_GET_DESIGNS,
  API_FIGMA_PROJECT,
  API_FIGMA_UPDATE,
} from "../constants";
import {
  APIResponse,
  DeleteFrameRequestData,
  DesignSummary,
  DuplicateFigmaFrameRequestData,
  FigmaData,
  FigmaDesignIdentifier,
  FigmaFrameIdentifier,
  FrameMetadataUpdateRequestData,
  GenerateFigmaFrameImageRequestData,
  UpdateFigmaFrameRequestData,
} from "../types";

const getFigmaFrame = async (data: FigmaFrameIdentifier) => {
  return axiosApi.post<APIResponse<FigmaData>>(API_FIGMA_CONTENT, { ...data });
};

const getFigmaFramesUnderDesign = async (data: FigmaDesignIdentifier) => {
  return axiosApi.post<APIResponse<Array<FigmaData>>>(API_FIGMA_PROJECT, {
    ...data,
  });
};

const updateFigmaFrameContent = async (data: UpdateFigmaFrameRequestData) => {
  return axiosApi.post<APIResponse<{}>>(API_FIGMA_UPDATE, { ...data });
};

const generateImagesInFrame = async (
  data: GenerateFigmaFrameImageRequestData
) => {
  return axiosApi.post<APIResponse<{}>>(API_FIGMA_GENERATE_PAGE_IMAGES, {
    ...data,
  });
};

const deleteFrame = async (data: DeleteFrameRequestData) => {
  return axiosApi.post<APIResponse<{}>>(API_FIGMA_DELETE, { ...data });
};

const duplicateFrame = async (data: DuplicateFigmaFrameRequestData) => {
  return axiosApi.post<APIResponse<FigmaData>>(API_FIGMA_DUPLICATE, {
    ...data,
  });
};

const updateFigmaFrameMetadata = async (
  data: FrameMetadataUpdateRequestData
) => {
  return axiosApi.post<APIResponse<{}>>(API_FIGMA_FRAME_UPDATE, {
    ...data,
  });
};

const getFigmaFramesForUser = async () => {
  return axiosApi.get<APIResponse<DesignSummary[]>>(API_FIGMA_GET_DESIGNS);
};

const figmaApi = {
  getFigmaFrame,
  getFigmaFramesUnderDesign,
  updateFigmaFrameContent,
  generateImagesInFrame,
  deleteFrame,
  duplicateFrame,
  updateFigmaFrameMetadata,
  getFigmaFrames: getFigmaFramesForUser,
};

export default figmaApi;
