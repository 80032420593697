/**
 * Validated provided string as an email.
 * @param email
 * @returns
 */
export const validateEmail = (email: string) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
};

/**
 * Validate password length.
 * @param password
 * @param confirmPassword
 * @returns
 */
export const validatePassword = (
  password: string,
  confirmPassword?: string
) => {
  const lengthvalid = password.length >= 8;
  const passwordsValid = confirmPassword ? confirmPassword === password : true;

  const error = lengthvalid
    ? passwordsValid
      ? ""
      : "Two passwords do not match."
    : "Password must be at least 8 characters long";

  return {
    isValid: lengthvalid && passwordsValid,
    error,
  };
};
