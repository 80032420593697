import { useEffect, useRef, useState } from "react";
import { CiTimer } from "react-icons/ci";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import figmaApi from "../api/figmaApi";
import InvitationModal from "../components/modals/InvitationModal";
import { useAuth } from "../hooks/useAuth";
import { DesignSummary } from "../types";
import { DesignInvitationModalData } from "../types/modal";
import { checkIsAdmin } from "../utils/auth-utils";
import { formatDateString } from "../utils/common-utils";
import { FaUserFriends } from "react-icons/fa";
import DesignUserManagementModal from "../components/modals/DesignUserManagementModal";

/**
 * Displays all the design files accessible to this user.
 * @returns
 */
function Dashboard() {
  const { userData } = useAuth();
  const hasFetchedData = useRef(false);
  const [data, setData] = useState<Array<DesignSummary>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [invitationModalData, setInvitationModalData] =
    useState<DesignInvitationModalData | null>(null);
  const [userManagementModalData, setUserManagementModalData] =
    useState<DesignInvitationModalData | null>(null);

  // fetch all accessible designs for this user
  useEffect(() => {
    if (!userData || hasFetchedData.current) return;
    hasFetchedData.current = true;
    setIsLoading(true);

    // fetch all designs available to this user
    const getDesignsOfUser = async () => {
      try {
        const response = await figmaApi.getFigmaFrames();

        if (response.data.statusCode !== 200) {
          throw response.data.message;
        }

        setData(response.data.data);
      } catch (error) {
        console.error(error);
        toast("Did not get any data to display.", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    getDesignsOfUser();
  }, [userData]);

  // open the user invitation modal
  const handleInviteClick = (item: DesignSummary) => {
    setInvitationModalData({
      design_id: item.design_id,
      design_name: item.design_name,
    });
  };

  return (
    <div className="page-content-wrapper">
      {isLoading && (
        <div className="loader-overlay">
          <div className="spinner-border text-primary" role="status" />
        </div>
      )}
      <div className="centered-container">
        <div className="text-center">
          <h1>Figma Press | Eight25</h1>
          {data.length > 0 && (
            <div className="card" style={{ width: "45rem" }}>
              <div className="card-body">
                <h2 className="card-title">Designs available</h2>
                <ul className="list-group list-group-flush">
                  {data.map((item) => (
                    <li
                      key={item.design_id}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div className="frame-name-wrapper">
                        {item.pending_updates_count > 0 && (
                          <CiTimer
                            className="pending-update-icon"
                            data-tooltip-id={`pending_update_${item.design_id}`}
                            data-tooltip-content={`${
                              item.pending_updates_count
                            } ${
                              item.pending_updates_count > 1
                                ? "frames"
                                : "frame"
                            } ${
                              item.pending_updates_count > 1 ? "need" : "needs"
                            } syncing.`}
                          />
                        )}
                        <Tooltip id={`pending_update_${item.design_id}`} />
                        <span>{item.design_name}</span>
                      </div>
                      <div className="action-wrapper d-flex gap-2 align-items-center justify-content-end">
                        <span
                          className="frame-metadata"
                          data-tooltip-id={`design_metadata_count_${item.design_id}`}
                          data-tooltip-content={`${item.frame_count} ${
                            item.frame_count > 1 ? "pages" : "page"
                          } in this design`}
                          data-tooltip-place="right-end"
                        >
                          <em>{item.frame_count}</em>
                          <Tooltip
                            id={`design_metadata_count_${item.design_id}`}
                          />
                        </span>
                        <Link
                          className="btn btn-secondary action-btn"
                          to={`/${item.design_id}`}
                          data-tooltip-id={`design_metadata_${item.design_id}`}
                          data-tooltip-content={`Last update: ${formatDateString(
                            item.latest_updated_at
                          )}`}
                          data-tooltip-place="top-end"
                        >
                          View
                          <Tooltip id={`design_metadata_${item.design_id}`} />
                        </Link>
                        {/* this is available for admins only */}
                        {userData && checkIsAdmin(userData?.role) && (
                          <>
                            <button
                              className="btn btn-primary action-btn"
                              data-tooltip-id={`invite_${item.design_id}`}
                              data-tooltip-content="Invite users to edit"
                              data-tooltip-place="top-end"
                              onClick={() => handleInviteClick(item)}
                            >
                              Invite
                              <Tooltip id={`invite_${item.design_id}`} />
                            </button>
                            <button
                              className="btn btn-secondary action-btn"
                              data-tooltip-id={`user-mgmt_${item.design_id}`}
                              data-tooltip-content="Manage user access"
                              data-tooltip-place="top-end"
                              onClick={() =>
                                setUserManagementModalData({
                                  design_id: item.design_id,
                                  design_name: item.design_name,
                                })
                              }
                            >
                              <FaUserFriends />
                              <Tooltip id={`user-mgmt_${item.design_id}`} />
                            </button>
                          </>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      {invitationModalData && (
        <InvitationModal
          {...invitationModalData}
          onCancel={() => setInvitationModalData(null)}
        />
      )}
      {userManagementModalData && (
        <DesignUserManagementModal
          {...userManagementModalData}
          onCancel={() => setUserManagementModalData(null)}
        />
      )}
    </div>
  );
}

export default Dashboard;
